
export class PwaConfig {
    private _supportsPWA = false;
    private _promptInstallEvent = null;
    private installPromptHandler = e => {
        e.preventDefault();
        this._supportsPWA = true;
        this._promptInstallEvent = e;
    };


    public init() {
        console.log('init PWA config...')
        window.addEventListener('beforeinstallprompt', this.installPromptHandler);
    }

    public  showInstallPwaPrompt = async () => {
        if (!this._supportsPWA || this._promptInstallEvent == null) {
            return false;
        }
        let userAction = await this._promptInstallEvent.prompt();
        if (userAction.outcome === "accepted") {
            console.log("PWA installing:" + userAction.outcome);
            this._promptInstallEvent = null;
            window.removeEventListener('transitionend', this.installPromptHandler);
            return true;
        }
        return false;
    };

    public supportsPWA = () => this._supportsPWA && this._promptInstallEvent != null;
}

export const PwaConfigInstance = new PwaConfig();
