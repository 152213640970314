import {createTheme, ThemeProvider} from '@mui/material/styles';
import './assets/styles/app.scss';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import MyRoutes from './routes';
import useChangeLanguage from './hooks/useChangeLanguage';
import {Suspense, useEffect} from 'react';
import AppLoader from './components/atoms/apploader';
import {useAppSelector} from './hooks/';
import {userManager} from './configs/OAuthConfig';
import WarningModal from './components/organisms/warningModal';
import { PwaConfigInstance } from './configs/PwaConfig';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#295BB7',
      },
      secondary: {
        main: '#EAEFF8',
        dark: '#D4DEF1',
      },
      error: {
        main: '#FF6B72',
      },
      info: {
        main: '#B4B5B8',
      },
      success: {
        main: '#FAFAFA',
        dark: '#E6E7E8',
      },
    },
  });

  useEffect(() => {
    userManager.events.addSilentRenewError(async () => {
      await userManager.removeUser();
      window.location.reload();
    });
    PwaConfigInstance.init();
  }, []);
  useChangeLanguage();

  const {dir} = useAppSelector(s => s.local);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<AppLoader />}>
        <BrowserRouter>
          <MyRoutes />
        </BrowserRouter>
      </Suspense>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        rtl={dir === 'rlt' ? true : false}
      />
      <WarningModal />
    </ThemeProvider>
  );
}

export default App;
